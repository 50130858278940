@import '../../../UI/global.module.scss';

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 80px;
	background-color: #E2F2FF;
	padding: 80px 80px 82px;

	.title {
		font-size: 45px;
		font-family: Roca One;
		font-weight: 400;
		line-height: 110%;
		letter-spacing: 2%;
		text-align: center;
	}

	.body {
		display: flex;
		flex-direction: column;
		gap: 64px;
		border-radius: 40px;
		background-color: #FFFFFF;
		padding: 40px 75px;
		max-width: 1278px;

		.containerCards {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr 1fr;
			row-gap: 45px;
			column-gap: 56px;
			
			.card {
				display: flex;
				flex-direction: row;
				gap: 16px;
	
		
				.image{
					width: 150px;
					height: 150px;
				}
		
				.text {
					display: flex;
					flex-direction: column;
					gap: 16px;

					.bold {
						font-weight: 700;
					}
	
					.cardTitle {
						font-size: 24px;
						font-family: Roca One;
						font-weight: 700;
						line-height: 30px;
					}
					
					.cardDescription {
						font-size: 22px;
						font-family: NT Bau;
						font-weight: 400;
						line-height: 130%;
						letter-spacing: -2%;
					}
				}
			}
		}
	}

	.button {
		width: 420px;
		height: 54px;
	}
}

.mainColor {
	color: $main-color;
}

.mobile {
	display: none;
}

@media only screen and (max-width: 768px) {
	.container {
		gap: 40px;
		padding: 32px 20px 40px;

		.body {
			padding: 24px 20px;
			border-radius: 12px;
			gap: 24px;
			
			.title {
				font-size: 30px;
				line-height: 110%;
				letter-spacing: 0.6px;
				text-align: center;
			}

			.containerCards {
				grid-template-columns: 1fr;
				grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;;
				row-gap: 8px;
	
				.card {
					flex-direction: column;
					gap: 8px;
					align-items: center;
	
					.image{
						width: 96px;
						height: 96px;
					}
			
					.text {
						gap: 8px;
	
						.cardTitle {
							font-size: 20px;
							font-weight: 400;
							line-height: 25px;
							letter-spacing: 0.6px;
							text-align: center;
						}
						
						.cardDescription {
							font-size: 18px;
							line-height: 24px;
							letter-spacing: -0.5px;
							text-align: center;
							min-height: 72px;
						}
					}
				}
			}
		}
		
		a {
			width: 100%;
		}
		.button {
			width: 100%;
			height: 48px;
		}
	}

	.mobile {
		display: block;
	}
}